.container .wrapper .menu .menuItem a {
  text-decoration: none;
  color: #fff;
}

.a {
  cursor: pointer;
  color: #fff;
}

.banderas {
  background-color: black;
  margin-left: 50;
  margin-top: 10px;
}

@media screen and (max-width:375px) {
  .banderas {
    position: relative;
    top: -4px;
    right: -30px;
  }

  .nav-item {
    display: none;
  }

  .icon {
    margin: 15px;
  }
}


@media screen and (max-width:510px) {
  .maps {
    position: relative;
    left: -250px;
  }

  .nav-item {
    display: none;
  }

  .banderas {
    position: relative;
    top: -4px;
    right: -30px;
  }

  .icon {
    margin: 15px;
  }
}