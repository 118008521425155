.movieGrid {
  display: grid;
  /* forma columns auto con un ancho de 230px */
  grid-template-columns: repeat(auto-fill, 230px);
  /* espacio entre img */
  gap: 40px;
  padding: 40px;
  justify-content: center;
}

@media screen and (max-width: 560px) {
  .movieGrid {
    grid-template-columns: 100%;
  } 
}