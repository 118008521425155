.bg-detail{
  display: flex;
  background-image: url(../../media/img-home-nuevo.png);
  background-repeat: no-repeat;
  /* background-attachment: fixed;
  background-size: cover; */
  overflow: hidden !important;
  background-size: cover;
}

.bg-detail img {
  object-fit: contain;
  margin-bottom: -30px;
  width: 420px;
  height: 520px;
}

.contenedor-imagen {
  margin-left: -15px;
  width: 75vw;
  max-width: 1000px;
  min-width: 250px;
}

.contenedor-imagen img {
  width: 100%;
  margin-left: 90px;
  border-radius: 15px;
}

@media screen and (max-width: 992px) {
.contenedor-imagen img {
  margin-left: 0;
}
}

@media screen and  (max-width: 856px) {
  .bg-detail img {
    width: 300px;
    height: 400px;
  }
}


@media screen and (max-width: 720px) {
h5 {
  font-size: 16px;
}
}


@media screen and (max-width: 497px) {
  h5 {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  h5 {
      font-size: 12px;
  }

  .boton-inicio {
      font-size: 10px;
      height: 30px;
      width: 70px;
  }
}