.bg-home1 {
  display: flex;
  background-image: url(../../media/img-home-nuevo.png);
  background-repeat: no-repeat;
  /* background-attachment: fixed;
  background-size: cover; */
  overflow: hidden !important;
  background-size: cover;
}

.bg-home1 img {
  object-fit: contain;
  margin-bottom: -30px;
  width: 420px;
  height: 520px;
}

.contenedor-parrafo {
  margin: 0 20px;
  position: relative;
}

.parrafo-home {
  text-align: center;
  margin-top: 65px;
  margin-bottom: -10px;
  font-weight: bold;
  font-size: 20px;
}

.calendario-home{
 margin-left: 600px;
}

.card-body{
  left: 600px;
  top: 95px;
}

.contenedor-imagenPerro {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
}

.imagen-perro {
  width: 700px;
  height: 400px;
}

.contenedor-textoPerro {
  height: 120px;
}

.texto-perro {
  text-align: center;
  margin: 25px 10px;
  font-size: 18px;
}

.contenedor-mapita {
  display: flex;
  flex-direction: column;
}

@media screen and  (max-width: 856px) {
  .bg-home1 img {
    width: 300px;
    height: 400px;
  }
}

@media screen and (max-width: 720px) {
  .imagen-perro {
    width: 500px;
    height: 280px;
  }

  .parrafo-home {
    /* margin-top: 120px; */
    font-size: 16px;
  }
}

/* @media screen and (max-width: 555px) {
  .parrafo-home {
    margin-top: 130px;
  }
} */


@media screen and (max-width: 497px) {
  .imagen-perro {
    width: 320px;
    height: 170px;
  }

  .texto-perro {
    text-align: center;
    margin: 25px 10px;
    font-size: 14px;
  }
}

/* @media screen and (max-width: 470px) {
  .parrafo-home {
    margin-top: 150px;
  }
} */

@media screen and (max-width: 400px) {
  .parrafo-home {
    /* margin-top: 180px; */
    font-size: 13px;
  }

}

/* @media screen and (max-width: 351px) {
  .parrafo-home {
    margin-top: 180px;
  }
} */


/* @media screen and (max-width: 320px) {
  .parrafo-home {
    margin-top: 190px;
  }
} */
